<template>
<div>
  <b-container class="py-5 text-white">
    <b-row class="py-5">
      <b-col>
        <h1 class="py-2">Browser based analytics</h1>
        <h4 class="py-2">Your data never leaves your computer</h4>
        <p>We do not send your data to a server. All processing is done in your browser</p>
        <h4 class="py-2">Choose app</h4>
        <div><b-button class="my-2" variant="light" @click="load('bestexecution')">Best execution</b-button></div>
        <div><b-button class="my-2" variant="light" @click="load('price')">Historical prices</b-button></div>
        <b-spinner variant="light" label="Spinning" v-if='loading'></b-spinner>
        <h5 class="py-5" v-if='loading'>...loading...</h5>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
export default {
  components: {
  },
  computed: {
  },
  created: async function () {
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    load: function (app) {
      this.loading = true
      if (app === 'bestexecution') {
        this.$router.push({ name: 'AppAnalyticsBestExecution' })
      }
      if (app === 'price') {
        this.$router.push({ name: 'AppAnalyticsStart' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
a, .nav-link {
  color: white;
}
a:hover, .nav-link:hover {
  color: white;
}
</style>
